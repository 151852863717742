<template>
  <form class="product__tag-list">
    <fieldset>
      <Swiper slides-per-view="auto" :space-between="12" :free-mode="true" :modules="modules">
        <SwiperSlide>
          <input type="checkbox" id="all" v-model="allSelected" @change="selectAll" />
          <label for="all">전체</label>
        </SwiperSlide>
        <SwiperSlide v-for="tag in tagList" :key="tag.id">
          <input
            type="checkbox"
            :id="tag.id"
            :value="tag.id"
            v-model="selectedTagList"
            @change="selectTag"
          />
          <label :for="tag.id">{{ tag.name }}</label>
        </SwiperSlide>
      </Swiper>
    </fieldset>
  </form>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useProductStore } from '@/stores/product';
import { storeToRefs } from 'pinia';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';

const store = useProductStore();

const modules = [FreeMode];

const { tagList, selectedTagList } = storeToRefs(store);

const allSelected = ref(selectedTagList.value.length === 0);

watch(selectedTagList, () => {
  if (selectedTagList.value.length === tagList.value.length) {
    return;
  }

  store.getProductList();
});

function selectAll() {
  if (selectedTagList.value.length === 0) {
    allSelected.value = true;
    return;
  }
  selectedTagList.value = [];
}

function selectTag() {
  if (selectedTagList.value.length === 0 || selectedTagList.value.length === tagList.value.length) {
    allSelected.value = true;
    selectedTagList.value = [];
    return;
  }

  allSelected.value = false;
}
</script>

<style scoped>
.product__tag-list {
  color: var(--Gray-50);
}
.product__tag-list .swiper-slide {
  width: fit-content;
}
.product__tag-list input[type='checkbox'] {
  display: none;
}
.product__tag-list label {
  flex-shrink: 0;
  display: block;
  width: fit-content;
  height: 42px;
  padding: 0 24px;
  border-radius: 48px;
  line-height: 42px;
}
.product__tag-list--home label {
  border: 1px solid var(--Gray-50);
  background-color: var(--White);
}
.product__tag-list--products label {
  border: 1px solid transparent;
  background-color: var(--Gray-20);
}
.product__tag-list input[type='checkbox']:checked + label {
  border-color: transparent;
  background-color: var(--Black);
  color: var(--Key);
  font-weight: 600;
}
</style>
