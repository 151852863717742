import axios from 'axios';
import { useUserStore } from '@/stores/user';
import router from '@/router';

const refreshQueue = [];
let isRefreshing = false;

const instance = axios.create({ baseURL: '/api/' });

instance.interceptors.request.use(
  (config) => {
    const store = useUserStore();

    if (store.isLogined) {
      config.headers.authorization = `Bearer ${store.accessToken}`;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const store = useUserStore();

    if (config.url !== '/auth/refresh/' && status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;

        if (await store.refreshAuth()) {
          refreshQueue.forEach((queue) => {
            instance(queue);
          });
          refreshQueue.length = 0;

          isRefreshing = false;
          return instance(config);
        }
      }

      refreshQueue.push(config);
    }

    if (status === 500) {
      router.push('/500');
    }

    return Promise.reject(error);
  }
);

export default instance;
