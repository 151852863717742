<template>
  <button type="button" class="product-card" @click="clickEvent(product.id)">
    <div class="product-card__img-wrapper">
      <img :src="product.sample_images" alt="product-img" />
    </div>
    <div class="product-card__content">
      <p class="product-card__content__title head-24-b">{{ product.model_name }}</p>
      <p class="product-card__content__min-quantity body-14-r">
        최소 주문량 : {{ product.min_quantity }}개
      </p>
      <p class="product-card__content__min-price body-18-b">
        {{ filters.price(product.min_price) }}원 ~
      </p>
    </div>
  </button>
</template>

<script setup>
import { inject } from 'vue';
import filters from '@/utils/filters';

defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const clickEvent = inject('productClickEvent');
</script>

<style scoped>
.product-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 232px;
  height: 100%;
  border-radius: 24px;
  border: 1px solid var(--Gray-30);
  text-align: start;
  overflow: hidden;
}
.product-card__img-wrapper {
  height: 230px;
}
.product-card__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 25px 25px 35px 25px;
}
.product-card__content__min-quantity {
  color: var(--Gray-50);
}
</style>
