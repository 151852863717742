<template>
  <footer>
    <router-link to="/" class="footer__logo sprite sprite--logo sprite--footer-logo">
      MAiD
    </router-link>
    <ul class="body-14-b">
      <li>
        <router-link to="/service">서비스 소개</router-link>
      </li>
      <li>
        <a :href="TERMS_LINK" target="_blank" rel="noopener noreferrer">이용약관</a>
      </li>
      <li>
        <a :href="TERMS_LINK" target="_blank" rel="noopener noreferrer">개인정보처리방침</a>
      </li>
      <li>
        <router-link to="/support">고객센터</router-link>
      </li>
    </ul>
    <p class="body-12-r">
      민트프라임 ㈜퍼스트핍 대표이사 김동욱 사업자등록번호 788-87-02526 통신판매신고
      2024-서울용산-0453
    </p>
    <span class="body-12-r">
      개인정보보호책임자 : 심정규 서울시 용산구 청파로 47길 46, 204호 | 고객만족센터 070-8080-2615
    </span>
  </footer>
</template>

<script setup>
const TERMS_LINK = 'https://www.notion.so/firstpip/c369621b6f01465e930c287994532b74?pvs=4';
</script>

<style scoped>
footer {
  position: relative;
  min-height: 181px;
  padding: 50px 100px;
  background-color: var(--Black);
  color: var(--White);
}
.workspace footer {
  background-color: var(--Gray-20);
  color: var(--Gray-50);
}
.footer__logo {
  position: absolute;
  top: 35px;
  right: 100px;
}
footer > ul {
  display: flex;
  gap: 48px;
}
footer > p {
  margin-top: 20px;
}
footer > span {
  display: block;
  margin-top: 4px;
}
</style>
