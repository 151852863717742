export default {
  /**
   * 자릿수에 맞춰 0 채우기
   *
   * @param {number} num 자릿수 맞출 숫자
   * @param {number} digitNum 자릿수
   * @return {number} 자릿수에 맞춘 숫자
   */
  fillZero(num, digitNum) {
    return ('0'.repeat(digitNum) + num).slice(digitNum * -1);
  },
  date(datetime) {
    return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
  },
  dateString(datetime) {
    const date = typeof datetime === 'string' ? new Date(datetime) : datetime;

    const year = date.getFullYear();
    const month = this.fillZero(date.getMonth() + 1, 2);
    const day = this.fillZero(date.getDate(), 2);

    return `${year}. ${month}. ${day}`;
  },
  datetimeString(datetime) {
    const date = typeof datetime === 'string' ? new Date(datetime) : datetime;

    const year = date.getFullYear();
    const month = this.fillZero(date.getMonth() + 1, 2);
    const day = this.fillZero(date.getDate(), 2);
    const hour = this.fillZero(date.getHours(), 2);
    const minute = this.fillZero(date.getMinutes(), 2);
    const second = this.fillZero(date.getSeconds(), 2);

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  },
  yearMonth(datetime) {
    const date = typeof datetime === 'string' ? new Date(datetime) : datetime;

    const year = date.getFullYear();
    const month = this.fillZero(date.getMonth() + 1, 2);

    return `${year}. ${month}`;
  },
  inputYearMonth(num) {
    return String(num)
      .replace(/[^0-9]/g, '')
      .slice(0, 6)
      .replace(/^(\d{4})(\d{1,2})$/g, '$1. $2')
      .replace(/(\.\s)$/g, '');
  },
  datepickerDateString(datetime) {
    const date = typeof datetime === 'string' ? new Date(datetime) : datetime;

    const year = date.getFullYear();
    const month = this.fillZero(date.getMonth() + 1, 2);
    const day = this.fillZero(date.getDate(), 2);

    return `${String(year).slice(2)}.${month}.${day}`;
  },
  inputDatepickerDateString(num) {
    return String(num)
      .replace(/[^0-9]/g, '')
      .slice(0, 6)
      .replace(/^(\d{2})(\d{1,2})(\d{0,2})$/g, '$1.$2.$3')
      .replace(/(\.{1,2})$/g, '');
  },
  price(num) {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
  },
  phoneNumber(num) {
    const number = String(num)
      .replace(/[^0-9]/g, '')
      .slice(0, 11);
    let phoneNum;

    if (number.length < 11) {
      phoneNum = number
        .replace(/^(\d{3})(\d{1,3})(\d{0,4})$/g, '$1 - $2 - $3')
        .replace(/(\s-\s{1,2})$/g, '');
    } else {
      phoneNum = number
        .replace(/^(\d{3})(\d{1,4})(\d{0,4})$/g, '$1 - $2 - $3')
        .replace(/(\s-\s{1,2})$/g, '');
    }

    return phoneNum;
  },
};
