import { defineStore, getActivePinia } from 'pinia';
import { ref, inject } from 'vue';
import * as userApi from '@/api/user';
import router from '@/router';

export const useUserStore = defineStore(
  'user',
  () => {
    const $cookies = inject('$cookies');

    const requestSnsLoginFlag = ref(false);
    const snsUserData = ref(null);
    const isLogined = ref(false);
    const userId = ref('');
    const email = ref('');
    const userName = ref('');
    const isVerified = ref(false);
    const accessToken = ref('');
    const shippingAddress = ref({});
    const requestPaymentFlag = ref(false);

    function $reset() {
      requestSnsLoginFlag.value = false;
      snsUserData.value = null;
      isLogined.value = false;
      userId.value = '';
      email.value = '';
      userName.value = '';
      isVerified.value = false;
      accessToken.value = '';
      $cookies.remove('refreshToken');
      shippingAddress.value = {};
      requestPaymentFlag.value = false;
    }

    function resetAllStore() {
      getActivePinia()._s.forEach((store) => store.$reset());
    }

    function signup(data) {
      return userApi.signup(data);
    }

    async function login(data, type = 'local') {
      const { data: result } = type === 'local' ? await userApi.login(data) : { data };

      isLogined.value = true;
      userId.value = result.user_id;
      email.value = result.email;
      userName.value = result.nickname;
      isVerified.value = result.verification;
      accessToken.value = result.access;
      $cookies.set('refreshToken', result.refresh);
    }

    function requestEmailVerification(data) {
      return userApi.requestEmailVerification(data);
    }

    function verifyEmail(key) {
      return userApi.verifyEmail(key);
    }

    async function verificationCheck() {
      const { data: result } = await userApi.verificationCheck();
      isVerified.value = result.verification;
    }

    async function logout() {
      const refreshToken = $cookies.get('refreshToken');

      await userApi.logout({ refresh: refreshToken });
      resetAllStore();
      router.push('/');
    }

    async function refreshAuth() {
      const refreshToken = $cookies.get('refreshToken');

      try {
        const { data: result } = await userApi.refreshAuth({ refresh: refreshToken });

        accessToken.value = result.access;
        return true;
      } catch (error) {
        switch (error.response.status) {
          case 400:
          case 401:
            resetAllStore();
            router.push('/');
            break;

          default:
            break;
        }
        return false;
      }
    }

    async function updateAccount(data) {
      const { data: result } = await userApi.updateAccount(data);

      userName.value = result.nickname;
    }

    function getAccountInfo() {
      return userApi.getAccountInfo();
    }

    function checkPassword(data) {
      return userApi.checkPassword(data);
    }

    function resetPassword(data) {
      return userApi.resetPassword(data);
    }

    async function getShippingAddress() {
      const { data: result } = await userApi.getShippingAddress();

      shippingAddress.value = result;
    }

    async function updateShippingAddress(data) {
      const { data: result } = await userApi.updateShippingAddress(data);

      shippingAddress.value = result;
    }

    async function deleteAccount() {
      await userApi.deleteAccount();

      logout();
    }

    return {
      requestSnsLoginFlag,
      snsUserData,
      isLogined,
      userId,
      email,
      userName,
      isVerified,
      accessToken,
      shippingAddress,
      requestPaymentFlag,
      $reset,
      signup,
      login,
      requestEmailVerification,
      verifyEmail,
      verificationCheck,
      logout,
      refreshAuth,
      updateAccount,
      getAccountInfo,
      checkPassword,
      resetPassword,
      getShippingAddress,
      updateShippingAddress,
      deleteAccount,
    };
  },
  { persist: true }
);
