<template>
  <form @submit.prevent="emit('submit')" class="search">
    <fieldset>
      <legend v-if="legendTitle.length > 0">
        {{ legendTitle }}
      </legend>
      <div>
        <button type="submit" class="sprite sprite--search">검색</button>
        <label>
          <input
            type="text"
            :placeholder="placeholder"
            autocomplete="off"
            :value="modelValue"
            @input="emit('update:modelValue', $event.target.value)"
            v-bind="$attrs"
          />
        </label>
      </div>
    </fieldset>
  </form>
</template>

<script setup>
defineProps({
  modelValue: { required: true },
  legendTitle: {
    data: String,
    default: '',
  },
  placeholder: {
    data: String,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue', 'submit']);
</script>

<style scoped>
.search div {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
}
.search label {
  flex: 1;
}
.search input {
  width: 100%;
  border: none;
}

.search--home legend {
  margin-left: 7px;
}
.search--home div {
  width: 620px;
  height: 64px;
  margin-top: 25px;
  border: 2px solid var(--White);
  border-radius: 48px;
}
.search--home input[type='text']::placeholder {
  color: var(--White);
}

.search--products div {
  width: 484px;
  height: 48px;
  border-radius: 62px;
  background-color: var(--Gray-20);
}
.search--products input[type='text']::placeholder {
  color: var(--Gray-40);
}

.search--editor div {
  height: 32px;
  padding: 8px 4px;
  border-radius: 8px;
  border: 1px solid var(--Gray-40);
}
.search--editor input[type='text']::placeholder {
  color: var(--Gray-40);
}

.search--orders div {
  flex-direction: row-reverse;
  width: 346px;
  height: 48px;
  border-radius: 48px;
  background-color: var(--White);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}
.search--orders input[type='text']::placeholder {
  color: var(--Gray-50);
}
</style>
