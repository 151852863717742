import { defineStore } from 'pinia';
import { ref } from 'vue';
import * as productApi from '@/api/product';

export const useProductStore = defineStore(
  'product',
  () => {
    const redirectFlag = ref(false);
    const tagList = ref([]);
    const selectedTagList = ref([]);
    const searchedQuery = ref('');
    const productList = ref([]);
    const selectedProductId = ref();
    const product = ref({});

    function $reset() {
      redirectFlag.value = false;
      tagList.value = [];
      selectedTagList.value = [];
      searchedQuery.value = '';
      productList.value = [];
      selectedProductId.value = undefined;
      product.value = {};
    }

    async function getTagList() {
      selectedTagList.value.length = 0;

      const { data: result } = await productApi.getTagList();

      tagList.value = result.tag_list;
    }

    async function getProductList() {
      const selectedTagStr =
        selectedTagList.value.length > 0 ? selectedTagList.value.toString() : '0';

      const { data: result } = await productApi.getProductList({
        params: { tag_id: selectedTagStr, search_query: searchedQuery.value },
      });

      productList.value = result.product_list;
    }

    async function getProduct(id) {
      product.value = {};

      const { data: result } = await productApi.getProduct(id);

      product.value = result;
    }

    async function getProductPrice(id, query) {
      return productApi.getProductPrice(id, query);
    }

    return {
      redirectFlag,
      tagList,
      selectedTagList,
      searchedQuery,
      productList,
      selectedProductId,
      product,
      $reset,
      getTagList,
      getProductList,
      getProduct,
      getProductPrice,
    };
  },
  { persist: true }
);
